import {withErrorBoundary} from "components/error/ErrorBoundary";
import {Box, NextLink, Typography} from "components/basic-components";
import styles from "./SkipLinks.module.scss";
import {List, ListItem} from "@mui/material";
import {useTranslation} from "hooks/translations/useCustomTranslation";
import React, {useEffect, useRef} from "react";
import {useContactOptions} from "context/ContactOptionsModalContext";

const SkipLinks = () => {
  const {t} = useTranslation('common');
  const {openModal} = useContactOptions();
  const skipLinks = [
    {text: t('skipLinkSearch'), anchorId: 'search', querySelector: 'input[name="stationTextField"]', fallbackAnchorId: 'search'},
    {text: t('skipLinkNav'), anchorId: 'navigationAnchor', querySelector: '#headerMenuLinks a#navbarLink0', fallbackAnchorId: 'navigationAnchor'},
    {text: t('skipLinkMain'), anchorId: 'mainContentComponentAnchor', querySelector: 'span#mainContentComponentAnchor', fallbackAnchorId: 'mainAnchor'},
    {text: t('skipLinkFooter'), anchorId: 'footerAnchor', querySelector: 'div#footerBox0 a', fallbackAnchorId: 'footerAnchor'},
    {text: t('skipLinkContact'), anchorId: null, querySelector: null, fallbackAnchorId: null},
  ];

  const keysPressed = useRef({});
  useEffect(() => {
    const handleKeyDown = (event) => {
      keysPressed.current[event.key] = true;

      // check for a specific keyboard keys combination
      if (keysPressed.current['Alt'] && keysPressed.current['Tab']) {
        // focus on first skip link to open menu
        event.preventDefault();
        event.stopPropagation();
        document.getElementById('firstSkipLink')?.focus();
      }
    };

    const handleKeyUp = (event) => {
      keysPressed.current[event.key] = false;
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  return (
    <Box className={styles.skipLinksWrapper}>
      <List className={styles.skipLinksList}>
        {skipLinks.map(({text, anchorId, querySelector, fallbackAnchorId}, index) => {
          const currentAnchor = typeof window !== 'undefined' && anchorId && document?.getElementById(anchorId) ? anchorId : fallbackAnchorId;
          return (
            <ListItem className={styles.skipLinksListItem} key={index}>
              <NextLink
                id={(index === 0 ? 'firstSkipLink' : null) as any}
                className={styles.skipLink} href={`#${currentAnchor}`}
                onClick={(event) => {
                  const querySelectorElement = querySelector ? document?.querySelector(querySelector) as HTMLInputElement : null;
                  if (querySelectorElement) {
                    querySelectorElement.focus();
                  } else if (text === t('skipLinkContact')) {
                    event.preventDefault();
                    event.stopPropagation();
                    openModal();
                  } else if (currentAnchor) {
                    document?.getElementById(currentAnchor)?.focus();
                  }
                }}
              >
                {text}
              </NextLink>
            </ListItem>
          );
        })}
      </List>
      <Box className={styles.seperator} />
      <Box className={styles.quickNavHint}>
        <Typography variant='formLabeling'>{t('openSkipLinkNav')}</Typography>
        <br />
        <Typography variant='formLabeling'>{t('openSkipLinkNavKeyCombi')}</Typography>
      </Box>
    </Box>
  );
}

export default withErrorBoundary(SkipLinks, 'SkipLinks');