import clsx from 'clsx';
import {ContactOptionsModalProvider} from 'context/ContactOptionsModalContext';
import {ContactOptionsModalPropsContext} from 'context/ContactOptionsModalPropsContext';
import {useTranslation} from 'hooks/translations/useCustomTranslation';
import {useCallback, useContext, useEffect, useRef, useState} from 'react';

import HeadsetMicOutlinedIcon from '@mui/icons-material/HeadsetMicOutlined';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import {SvgIconTypeMap, useMediaQuery} from '@mui/material';
import {OverridableComponent} from '@mui/material/OverridableComponent';
import FocusTrap from '@mui/material/Unstable_TrapFocus';

import {breakpoint} from 'styles/mui/scss';

import {Box, Grid} from 'components/basic-components';
import Modal from 'components/basic-components/Modal';
import ScrollBox from 'components/basic-components/ScrollBox';
import FeedbackDrawer from 'components/layout-components/FeedbackDialogue/FeedbackDrawer';

import {ContactOptionsContent} from './ContactOptionsContent';
import styles from './ContactOptionsModal.module.scss';
import {CloseButton} from './components/CloseButton';
import {ContactHeader} from './components/ContactHeader';
import {ContactOptions} from './components/ContactOptions';
import {DesktopOpenButton} from './components/DesktopOpenButton';
import {MobileHeader} from './components/MobileHeader';
import {MobileMenu} from './components/MobileMenu';
import {MobileOpenButton} from './components/MobileOpenButton';

const allVariants: TVariantsType[] = ['mail', 'phone', 'callback', 'whatsapp'];
const defaultValue = 'mail';

export const ContactOptionsModal = ({isFooterVisible, children}) => {
  const [open, setOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const contactOptionsModals = useContext(ContactOptionsModalPropsContext);
  const {
    contactOptionCallback,
    contactOptionMail,
    contactOptionPhone,
    contactOptionWhatsapp,
    customText,
    backgroundImage,
  } = contactOptionsModals || {};
  const getVariantsFromContactOptionsIfExists = useCallback(() => {
    const variants: TVariantsType[] = [];
    if (contactOptionMail) {
      variants.push('mail');
    }
    if (contactOptionCallback) {
      variants.push('callback');
    }
    if (contactOptionPhone) {
      variants.push('phone');
    }
    if (contactOptionWhatsapp) {
      variants.push('whatsapp');
    }
    return variants;
  }, [contactOptionCallback, contactOptionMail, contactOptionPhone, contactOptionWhatsapp]);
  const variants = getVariantsFromContactOptionsIfExists();
  const {t} = useTranslation('ContactOptionsModal');
  const [selectedVariant, setSelectedVariant] = useState<TVariantsType>(null);
  const headerRef = useRef<HTMLDivElement>();
  const [headerHeight, setheaderHeight] = useState(0);
  useEffect(() => {
    if(headerRef?.current?.offsetHeight) {
      setheaderHeight(headerRef?.current?.offsetHeight)
    }
  }, [headerRef, selectedVariant, open,mobileMenuOpen])
  const closeHandler = useCallback(() => {
    setOpen(false);
    setSelectedVariant(null);
  }, [setOpen, setSelectedVariant]);
  const isDesktop = useMediaQuery(`(min-width:${breakpoint.md}px)`);
  useEffect(() => {
    if (isDesktop && !selectedVariant && open) {
      setSelectedVariant(defaultValue);
    }
  }, [isDesktop, open]);
  return (
    <ContactOptionsModalProvider
      openFeedback={() => setFeedbackOpen(true)}
      closeModal={closeHandler}
      openModal={() => setOpen(true)}
      openModalAndSelectVariant={(variant: TVariantsType) => {
        setOpen(true);
        if (variants?.includes(variant)) {
          setSelectedVariant(variant);
        }
      }}
    >
      <div>
      <style jsx global>{`
        :root {
          --header-height: ${headerHeight}px;
        }
      `}</style>
        <Modal disablePortal onClose={closeHandler} open={open}>
          <Box
            component={'section'}
            sx={{
              backgroundImage: {
                md:
                  selectedVariant &&
                  backgroundImage?.data?.attributes?.url &&
                  selectedVariant !== 'mail'
                    ? `url(${backgroundImage?.data?.attributes?.url})`
                    : null,
              },
            }}
            className={styles.modalContainer}
          >
            <FocusTrap open>
              <Box sx={{position: 'relative'}}>
                <CloseButton onClick={closeHandler} />
                <Grid
                  xxs={12}
                  className={clsx(styles.titleWrapper, {[styles.isHidden]: !!selectedVariant})}
                >
                  <ContactHeader ref={headerRef} />
                </Grid>
                <Grid
                  sx={{display: {md: 'none'}}}
                  xxs={12}
                  className={clsx(styles.titleWrapper, {[styles.isHidden]: !selectedVariant})}
                >
                  <MobileHeader
                    variant={selectedVariant}
                    handleBackButton={() => setSelectedVariant(null)}
                  />
                </Grid>
                <ScrollBox
                  className={clsx(styles.scrollContainer, {[styles.selectPage]: !selectedVariant})}
                >
                  <Grid container spacing={'25px'}>
                    <Grid
                      className={clsx({[styles.isHidden]: !!selectedVariant || mobileMenuOpen})}
                      xxs={12}
                      md={4}
                      lg={3}
                    >
                      <ContactOptions
                        customText={customText}
                        selectedVariant={selectedVariant}
                        setSelectedVariant={setSelectedVariant}
                        variants={allVariants?.filter(variant => variants.includes(variant))}
                      />
                    </Grid>

                    <Grid
                      className={clsx(styles.contactOptionsContentWrapper, {
                        [styles.isHidden]: !selectedVariant,
                      })}
                      xxs={12}
                      md={8}
                      lg={9}
                    >
                      <ContactOptionsContent variant={selectedVariant} />
                    </Grid>
                  </Grid>
                </ScrollBox>
              </Box>
            </FocusTrap>
          </Box>
        </Modal>

        <Modal disablePortal onClose={() => setMobileMenuOpen(false)} open={mobileMenuOpen}>
          <Box className={styles.mobileMenuContainer}>
            <MobileMenu
              goToWhatsapp={() => {
                setMobileMenuOpen(false);
                setOpen(true);
                setSelectedVariant('whatsapp');
              }}
              goToContact={() => {
                setMobileMenuOpen(false);
                setOpen(true);
              }}
              goToFeedback={() => {
                setOpen(false);
                setMobileMenuOpen(false);
                setFeedbackOpen(true);
              }}
            />
          </Box>
        </Modal>
        <FeedbackDrawer
          open={feedbackOpen}
          onClose={() => {
            setFeedbackOpen(false);
          }}
        />
        <DesktopOpenButton
          notificationText={t('notificationTextContact')}
          open={open}
          buttonText={t('openButton')}
          color="black"
          setOpen={setOpen}
          timeout={5000}
          showDuration={10000}
        />
        <DesktopOpenButton
          notificationText={t('notificationTextFeedback')}
          timeout={20000}
          showDuration={10000}
          open={open}
          buttonText={t('feedback')}
          color="yellow"
          position="bottom"
          setOpen={() => setFeedbackOpen(true)}
        />
        <MobileOpenButton
          isHidden={isFooterVisible || feedbackOpen || open || !!selectedVariant}
          setMobileMenuOpen={setMobileMenuOpen}
          mobileMenuOpen={mobileMenuOpen}
        />
      </div>
      {children}
    </ContactOptionsModalProvider>
  );
};

export const variantsConfig: {
  type: TVariantsType;
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string;
  };
}[] = [
  {
    type: 'whatsapp',
    Icon: WhatsAppIcon,
  },
  {
    type: 'mail',
    Icon: MailOutlineIcon,
  },
  {
    type: 'phone',
    Icon: LocalPhoneIcon,
  },
  {
    type: 'callback',
    Icon: HeadsetMicOutlinedIcon,
  },
];

export type TVariantsType = 'whatsapp' | 'mail' | 'phone' | 'callback';
