import {StarcarDateRangePickerStateContext} from 'hooks/BPR/useStarcarDateRangePickerState';
import {FormProvider} from 'hooks/custom-react-hook-form';
import React, {useState} from 'react';

import {palette} from 'styles/mui/scss';

import {Box, DateRangePicker, Typography} from 'components/basic-components';
import {withErrorBoundary} from 'components/error/ErrorBoundary';
import ContentWrapper from 'components/layout-components/ContentWrapper/ContentWrapper';

import {useBestPriceRechner} from '../../../hooks/BPR/useBestPriceRechner';
import BestPriceRechnerMobile from './BPRMobile/BestPriceRechnerMobile';
import FormInputDistance from './FormFields/FormInputDistance';
import SelectCategory from './FormFields/SelectCategory';
import SubmitButton from './FormFields/SubmitButton';
import StationSelectionFlyout from './StationSelection/StationSelection';
import FormInputOneWay from "components/section-components/bpr/FormFields/FormInputOneWay";
import styles from './BestPriceRechner.module.scss';
import clsx from "clsx";
import {useTranslation} from "next-i18next";
import { useHideOneWayForNotSupportedStationAndCategory } from './hooks/useHideOneWayForNotSupportedStationAndCategory';

const BestPriceRechner = props => {
  const {t} = useTranslation('bpr');
  const [stationFlyoutAnchorLeft, setStationFlyoutAnchorLeft] = useState<number>();

  const {
    formMethods,
    onSubmit,
    emergencyOnPointerDownHandler,
    control,
    errors,
    fieldValues,
    stationsById,
    isDesktop,
    bprRef,
    starcarDateRangePickerState,
    stations,
    presetStation,
    getAvailableReturnStations,
    filteredOneWayStations,
    filteredOneWayReturnStations,
    isLoadingReturnStations,
    setOneWayStationsDistance,
    setOneWayStationsDistanceText,
    oneWayStationsDistanceText,
  } = useBestPriceRechner(props);
  let hideOneWayForCategoryIfStationDoesntSupportIt = useHideOneWayForNotSupportedStationAndCategory({formMethods, stations, presetReturnStation: false});

  return (
    <FormProvider {...formMethods}>
      <StarcarDateRangePickerStateContext.Provider value={starcarDateRangePickerState}>
        <span className={styles.anchorLink}><span id="search" tabIndex={-1} /></span>
        <form
          onPointerDown={emergencyOnPointerDownHandler}
          onSubmit={formMethods.handleSubmit(onSubmit)}
          id="form-best-price"
          ref={bprRef}
        >
          {isDesktop ? (
            <ContentWrapper sx={{backgroundColor: palette.colorBlackAlt}}>
              <Box sx={{margin: '0 3%', padding: '10px 0', color: 'white'}}>
                <SelectCategory />
                <Box sx={{display: 'flex', columnGap: {lg: '5px', xxl: '20px'}, marginTop: '5px'}}>
                  <Box
                    data-form-id="InputStation"
                    className={clsx({
                      [styles.inputStationBox]: !fieldValues.oneWay,
                      [styles.inputOneWayStationBox]: fieldValues.oneWay,
                    })}
                  >
                    <StationSelectionFlyout
                      name="stationTextField"
                      control={control}
                      disabled={!!presetStation}
                      errors={errors}
                      setStationTextField={(value: string) => {
                        formMethods.setValue('stationTextField', value, {
                          shouldDirty: true,
                          shouldTouch: true,
                        });
                        starcarDateRangePickerState.resetDatepicker();
                        starcarDateRangePickerState.resetFormValues();
                        starcarDateRangePickerState.setCalendarOpen(!fieldValues.oneWay);
                        formMethods.clearErrors(['station', 'stationTextField']);
                      }}
                      setStation={station => {
                        formMethods.setValue('station', station?.id || '', {
                          shouldDirty: true,
                          shouldTouch: true,
                        });
                        console.log('setStation', station);
                        formMethods.clearErrors(['station', 'stationTextField']);
                      }}
                      stations={fieldValues.oneWay ? filteredOneWayStations : stations}
                      selectedStation={stationsById?.[fieldValues.station] || null}
                      value={fieldValues.stationTextField}
                      isWidget={false}
                      showPopularStations={false}
                      isOneWay={fieldValues.oneWay}
                      getAvailableReturnStations={getAvailableReturnStations}
                      setOneWayStationsDistance={setOneWayStationsDistance}
                      setOneWayStationsDistanceText={setOneWayStationsDistanceText}
                      stationFlyoutAnchorLeft={stationFlyoutAnchorLeft}
                      setStationFlyoutAnchorLeft={setStationFlyoutAnchorLeft}
                    />

                    <Box data-form-id="FormInputOneWay" sx={{marginTop: '5px'}}>
                      <FormInputOneWay
                        hideOneWayForCategoryIfStationDoesntSupportIt={hideOneWayForCategoryIfStationDoesntSupportIt}
                        control={control}
                        checkboxColor={palette.colorWhite}
                        labelColor={palette.colorMidGrey}
                        checkboxSize={'small'}
                      />
                    </Box>
                  </Box>

                  {fieldValues.oneWay ? (
                    <Box
                      data-form-id="InputReturnStation"
                      className={styles.inputOneWayStationBox}
                    >
                      <StationSelectionFlyout
                        name="returnStationTextField"
                        control={control}
                        errors={errors}
                        setStationTextField={(value: string) => {
                          formMethods.setValue('returnStationTextField', value, {
                            shouldDirty: true,
                            shouldTouch: true,
                          });
                          starcarDateRangePickerState.setCalendarOpen(true);
                          formMethods.clearErrors('returnStation');
                        }}
                        setStation={returnStation => {
                          formMethods.setValue('returnStation', returnStation?.id || '', {
                            shouldDirty: true,
                            shouldTouch: true,
                          });
                          formMethods.clearErrors('returnStation');
                        }}
                        stations={filteredOneWayReturnStations}
                        selectedStation={fieldValues.returnStation ? stationsById?.[fieldValues.returnStation] : null}
                        value={fieldValues.returnStationTextField}
                        isWidget={false}
                        showPopularStations={false}
                        isLoadingReturnStations={isLoadingReturnStations}
                        isOneWay={fieldValues.oneWay}
                        disabled={!fieldValues.station || !fieldValues.stationTextField}
                        getAvailableReturnStations={getAvailableReturnStations}
                        setOneWayStationsDistance={setOneWayStationsDistance}
                        setOneWayStationsDistanceText={setOneWayStationsDistanceText}
                        stationFlyoutAnchorLeft={stationFlyoutAnchorLeft}
                        setStationFlyoutAnchorLeft={setStationFlyoutAnchorLeft}
                      />
                      {oneWayStationsDistanceText ? (
                        <Box className={styles.oneWayDistanceBox}>
                          <Typography variant="formLabeling" sx={{fontSize: {lg: '10px', xl: '11px'}}}>
                            {t('oneWayDistance')}: {oneWayStationsDistanceText}
                          </Typography>
                        </Box>
                      ): null}
                    </Box>
                  ): null}

                  <Box
                    data-form-id="InputDate"
                    sx={{width: {lg: '55%', xl: '47%', xxl: '45%'}, height: 'fit-content'}}
                  >
                    <DateRangePicker isWidget={false} />
                  </Box>
                  <Box
                    data-form-id="InputDistance"
                    sx={{width: {lg: '15%'}, height: 'fit-content'}}
                  >
                    <FormInputDistance
                      control={control}
                      errors={errors}
                      isWidget={false}
                      disabled={!(fieldValues?.stationTextField && fieldValues?.station || fieldValues?.distance)}
                    />
                  </Box>
                  <SubmitButton
                    opacity={
                      (fieldValues.arrival &&
                        fieldValues.departure &&
                        fieldValues.station &&
                        !Object.keys(errors)?.length &&
                        1) ||
                      0.7
                    }
                  />
                </Box>
              </Box>
            </ContentWrapper>
          ) : (
            <BestPriceRechnerMobile
              presetStation={presetStation}
              errors={errors}
              hideOneWayForCategoryIfStationDoesntSupportIt={hideOneWayForCategoryIfStationDoesntSupportIt}
              stations={fieldValues.oneWay ? filteredOneWayStations : stations}
              stationsById={stationsById}
              returnStations={filteredOneWayReturnStations}
              getAvailableReturnStations={getAvailableReturnStations}
              setOneWayStationsDistance={setOneWayStationsDistance}
              setOneWayStationsDistanceText={setOneWayStationsDistanceText}
              oneWayStationsDistanceText={oneWayStationsDistanceText}
            />
          )}
        </form>
      </StarcarDateRangePickerStateContext.Provider>
    </FormProvider>
  );
};

export default React.memo(withErrorBoundary(BestPriceRechner, 'BestPriceRechner'));
