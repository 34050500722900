import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import styles from './ContactBubbleNotification.module.scss';
import BearImage from 'public/assets/bear.png'
import { CustomIcon } from 'components/basic-components/CustomIcon/CustomIcon';
import clsx from 'clsx';

const ContactBubbleNotification = ({ onClick, timeout = 5000, showDuration = 5000, notificationText, position = 'top' }) => {
  const { t } = useTranslation('ContactOptionsModal');
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (!sessionStorage.getItem(`modalClosed-${position}`)) {
      setTimeout(() => {
        setOpen(true);
        if(showDuration) {
          setTimeout(() => {
            setOpen(false);
          }, showDuration)
        }
      }, timeout);
    }
  }, []);

  const onClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(false);
    // Save the state in sessionStorage
    sessionStorage.setItem(`modalClosed-${position}`, 'true');
  };
  const onClickHandler = () => {
    setOpen(false);
    if(onClick) {
      onClick();
    }
  }
  return (
    <div onClick={onClickHandler} className={clsx(styles.container,styles[position], {[styles.hidden]: !open})}>
      <div className={styles.bubble}>
      <div className={styles.avatar}>
        <Image
          src={BearImage} // replace with your actual image path
          alt="Bear with a hat"
          width={40}
          height={40}
        />
      </div>
        <Typography className={styles.notificationText} component={"span"} variant="body1" color="textPrimary">
          {notificationText} 
        </Typography>
        <div className={styles.arrowWrapper} >
            <div className={styles.arrowRight} />
        </div>
        <button onClick={onClose} aria-label={t('closeNotificationPopup')} className={styles.closeIcon}><CustomIcon name="close" className={styles.icon} /></button>
      </div>
    </div>
  );
};

export default ContactBubbleNotification;
